<template>
  <div class="bookhouse">
    <div class="book-contect">
      <div class="book-title">Payment Information</div>
      <div class="bk_contant">
        <el-form :model="data">
          <div class="row item-row">
            <el-form-item label="First Name">
              <el-input v-model="data.first_name" placeholder="First Name"></el-input>
            </el-form-item>
            <el-form-item label="Last Name" required>
              <el-input v-model="data.last_name" placeholder="Last Name"></el-input>
            </el-form-item>
            <el-form-item label="Street Address 1">
              <el-input v-model="data.street1" placeholder="Street Address 1"></el-input>
            </el-form-item>
            <el-form-item label="Street Address 2">
              <el-input v-model="data.street2" placeholder="Street Address 2"></el-input>
            </el-form-item>

            <el-form-item label="City">
              <el-input v-model="data.city" placeholder="City"></el-input>
            </el-form-item>

            <el-form-item label="State">
              <el-input v-model="data.state" placeholder="State"></el-input>
            </el-form-item>

            <el-form-item label="Country">
              <el-input v-model="data.country" placeholder="Country"></el-input>
            </el-form-item>

            <el-form-item label="Zip Code">
              <el-input v-model="data.zip_code" placeholder="Zip Code"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div class="bk_btn">
      <el-button class="primary-button" @click="save" v-loading.fullscreen.lock="loading" >
        <p class="row btn-row">
          <span>Confirm</span><i class="el-icon-right"></i>
        </p>
      </el-button>
    </div>
  </div>
</template>
<script>
import { apiGetListing, apiUpdateListing } from "../../API/api";
export default {
  data() {
    return {
      data: {
        first_name: "",
        last_name: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
      },
      loading: false,
    };
  },
  methods: {
    getListing() {
      apiGetListing(this.$route.params.id).then((res) => {
        this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
        this.data = res.payment_information;
      });
    },
    preStep() {
      this.$router.push({
        name: "property-service",
        params: { id: this.$route.params.id },
      });
    },
    // nextStep() {
    //   this.$router.push({
    //     name: "property-service",
    //     params: { id: this.$route.params.id },
    //   });
    // },
    save() {
      this.loading = true;
      apiUpdateListing(this.$route.params.id, { payment_information: this.data })
          .then((res) => {
            this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
            this.data = res.payment_information;
            this.$message.success("Save successfully");
          })
          .catch((error) => {
            this.$message.error("Save failed");
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  created() {
    if (this.$route.params.id) {
      // if (this.$store.state.listing.listing_id === this.$route.params.id - 0) {
      //   this.data = this.$store.state.listing.requirement;
      // } else {
      this.getListing();
      // }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
.bookhouse {
  /deep/input[type=number] {
    -moz-appearance:textfield;
  }
  /deep/input[type=number]::-webkit-inner-spin-button,
  /deep/input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .book-title {
    text-align: left;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 18px;
    color: #36425d;
  }
  .bk_contant {
    margin: 20px 0 0 0;
    background: #fff;
    .item-row {
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      .date-item{
        margin-top: 0;
        justify-content: space-between;
        width: calc(calc(100% - 20px) / 2);
        @media (max-width:1200px) {
          width: 100%;
        }
      }
      /deep/ .el-form-item {
        text-align: left;
        width: calc(calc(100% - 20px) / 2);
        .el-select,
        .el-input {
          width: 100%;
        }
        .el-input__inner {
          height: 40px;
          line-height: normal;
        }
      }
      .book-form-title {
        margin: 0;
        line-height: 20px;
      }
    }

    /deep/ .el-link.el-link--default {
      font-size: 20px;
    }
    .bk_checkall {
      width: 100%;
      text-align: left;
      /deep/ .el-checkbox-button,
      /deep/ .el-checkbox-button__inner {
        width: 80%;
        max-width: 200px;
      }
      /deep/ .el-checkbox-button__inner {
        background: #f2f2f2;
        &:hover {
          background-color: #f2f2f2;
          color: #333;
          border-color: none;
        }
      }
      /deep/ .el-checkbox-button.is-checked:first-child
      .el-checkbox-button__inner {
        background: #678993;
        color: #fff;
        border-color: #678993;
      }
      /deep/ .el-checkbox-button.is-focus .el-checkbox-button__inner {
        border-color: #f2f2f2;
      }
      /deep/ .el-checkbox-button:last-child .el-checkbox-button__inner {
        width: 100%;
      }
      /deep/ .el-row {
        margin-top: 0px;
      }
      /deep/ .grid-content {
        margin-top: 10px;
      }
    }
    @media (max-width: 850px) {
      .bk_restir,
      .bk_checkall {
        width: 80%;
      }
    }
    @media (max-width: 660px) {
      .bk_btn .el-button {
        width: 50%;
      }
    }

  }
  .book-contect{
    position: relative;
    padding: 20px;
    min-height: 600px;
    &::after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border: 1px solid #E1E9EF;
    }
  }


  .bk_btn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    height: 80px;
    align-items: center;
    padding: 0 20px;
    /deep/ .el-button {
      .btn-row {
        width: 100px;
        margin: 0;
        justify-content: space-between;
        i {
          font-family: "Roboto-Bold", sans-serif;
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width:992px) {
    .bk_contant .item-row {
      display: block;
      .date-item .el-form-item:last-of-type{margin-left: 10px;}
      /deep/ .el-form-item {width: 100%;}
    }
  }
}
</style>